import { Link } from "gatsby"
import React from "react"

import "./submenutreneri.scss"

import ImgTreneriPixa from "../../images/treneri/jiri-pixa.jpg"
import ImgTreneriSevcik from "../../images/treneri/jiri-sevcik.jpg"
import ImgTreneriRezler from "../../images/treneri/zdenek-rezler.jpg"
import ImgTreneriMihalikova from "../../images/treneri/monika-mihalikova.jpg"
import ImgTreneriZabystrzan from "../../images/treneri/petr-zabystrzan.jpg"
import ImgTreneriMaizlish from "../../images/treneri/irina-maizlish.jpg"
import ImgTreneriSuchy from "../../images/treneri/jaroslav-suchy.jpg"
import ImgTreneriHybsova from "../../images/treneri/libuse-hybsova.jpg"
import ImgTreneriTancibudek from "../../images/treneri/milos-tancibudek.jpg"
import ImgTreneriPolacek from "../../images/treneri/marek-polacek.jpg"

const Submenutreneri = () => (

	<div className="stk-submenutreneri">
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/jiri-pixa">
			<img className="stk-submenutreneri__image" alt="Jiří Pixa" src={ImgTreneriPixa} />
			Jiří Pixa
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/jiri-sevcik">
			<img className="stk-submenutreneri__image" alt="Jiří Ševčík" src={ImgTreneriSevcik} />
			Jiří Ševčík
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/zdenek-rezler">
			<img className="stk-submenutreneri__image" alt="Zdeněk Rezler" src={ImgTreneriRezler} />
			Zdeněk Rezler
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/monika-mihalikova">
			<img className="stk-submenutreneri__image" alt="Monika Mihalíková" src={ImgTreneriMihalikova} />
			Monika Mihalíková
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/petr-zabystrzan">
			<img className="stk-submenutreneri__image" alt="Petr Zabystrzan" src={ImgTreneriZabystrzan} />
			Petr Zabystrzan
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/irina-maizlish">
			<img className="stk-submenutreneri__image" alt="Irina Maizlish" src={ImgTreneriMaizlish} />
			Irina Maizlish
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/jaroslav-suchy">
			<img className="stk-submenutreneri__image" alt="Jaroslav Suchý" src={ImgTreneriSuchy} />
			Jaroslav Suchý
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/libuse-hybsova">
			<img className="stk-submenutreneri__image" alt="Libuše Hybšová" src={ImgTreneriHybsova} />
			Libuše Hybšová
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/milos-tancibudek">
			<img className="stk-submenutreneri__image" alt="Miloš Tancibudek" src={ImgTreneriTancibudek} />
			Miloš Tancibudek
		</Link>
		<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/marek-polacek">
			<img className="stk-submenutreneri__image" alt="Marek Poláček" src={ImgTreneriPolacek} />
			Marek Poláček
		</Link>
	</div>

)

export default Submenutreneri