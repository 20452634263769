import React from "react"

import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Kurzy from "../../components/kurzy/kurzy"

import "../../components/layout/layout.scss"
import "../../components/submenutreneri/submenutreneri.scss"
import "../index.scss"
import "../tanecni-kurzy.scss"

const SoutezniTanecniParyBam = () => (

	<Layout>
		<Seo title="Vrcholové soutěžní taneční páry - třídy B,A,M" description="Vrcholové soutěžní taneční páry - třídy B,A,M" />
		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">Vrcholové soutěžní taneční páry - třídy B,A,M</h1>
			<p className="mb-4">Taneční sport - soutěže ve&nbsp;standardních a&nbsp;latinsko-amerických tancích.</p>

			<Row>
				<Col xl="9" className="pb-5">

					<Row className="stk-seminar mb-2 mt-2">
						<Col lg="12" xl="6">
							<div className="stk-seminare__item">
								<span className="stk-seminare__day">ÚT</span>
								<span className="stk-seminare__time">18:30<br /><span className="stk-seminare__dash">-</span><br />20:00</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Latinský seminář - Irina</span>
									<span className="stk-seminare__subtitle">všechny taneční páry LAT</span>
								</span>
							</div>
						</Col>
						<Col lg="12" xl="6">
							<div className="stk-seminare__item">
								<span className="stk-seminare__day">ČT</span>
								<span className="stk-seminare__time">18:30<br /><span className="stk-seminare__dash">-</span><br />20:00</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Latinský seminář - Zdeněk</span>
									<span className="stk-seminare__subtitle">všechny taneční páry LAT</span>
								</span>
							</div>
						</Col>
						<Col lg="12" xl="6">
							<div className="stk-seminare__item" to="../../practise">
								<span className="stk-seminare__day">ÚT</span>
								<span className="stk-seminare__time">20:00<br /><span className="stk-seminare__dash">-</span><br />22:00</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Latinský practise STK</span>
									<span className="stk-seminare__subtitle">všechny soutěžní páry LAT</span>
								</span>
							</div>
						</Col>
						<Col lg="12" xl="6">
							<div className="stk-seminare__item">
								<span className="stk-seminare__day">ST</span>
								<span className="stk-seminare__time">19:40<br /><span className="stk-seminare__dash">-</span><br />22:45</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Standardní seminář - Monika + Petr</span>
									<span className="stk-seminare__subtitle">všechny taneční páry STT</span>
								</span>
							</div>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2 pb-2">
							Místo:
						</Col>
						<Col xl="5">
							<p>
								<h3 className="stk-h3">Taneční sál - budova CASABLANCA</h3>
								Vinohradská 184, Praha-Vinohrady, 130&nbsp;00<br />
								<a className="stk-button stk-button--small mt-2" href="https://goo.gl/maps/gviNHcdBLMo6dmwg7" target="_blank" rel="noreferrer">zobrazit na mapě</a>
							</p>
							<p>
								<h3 className="stk-h3">Taneční sál Místo tance</h3>
								Na Petřinách 82, Praha&nbsp;6, 162&nbsp;00<br />
								<a className="stk-button stk-button--small mt-2" href="https://goo.gl/maps/jmPqFoJ1xi9hG7fd6" target="_blank" rel="noreferrer">zobrazit na mapě</a>
							</p>
						</Col>
						<Col xl="5">
							<p>
								<h3 className="stk-h3">Bohemia Dance</h3>
								U&nbsp;Měšťanského pivovaru 934/4, Praha&nbsp;7 - Holešovice, 170&nbsp;00<br />
								<a className="stk-button stk-button--small mt-2" href="https://goo.gl/maps/N9xjzfimkU9hR4ng8" target="_blank" rel="noreferrer">zobrazit na mapě</a>
							</p>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Co se naučíte:
						</Col>
						<Col xl="10">
							Standardní a latinsko-americké tance na&nbsp;soutěžní úrovni.<br />
							V ceně je minimálně <strong>15x vedený taneční seminář</strong>, <strong>volné tréninky</strong> od&nbsp;pondělí do&nbsp;neděle a&nbsp;<Link to="../../practise">úterní latinský practice</Link> od&nbsp;20:00 hodin.
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Cena:
						</Col>
						<Col xl="10">
							<span className="stk-h2 stk-h2--yellow">4950,-</span> / 5 měsíců
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Ostatní:
						</Col>
						<Col xl="10">
							možnost volných tréninků na&nbsp;sálech STK&nbsp;Praha dle&nbsp;rozvrhu<br />
							možnost individuálních lekcí s&nbsp;<Link to="../../treneri">trenéry STK&nbsp;Praha</Link>
						</Col>
					</Row>

				</Col>

				<Col xl="3" className="pb-5">
					<h2 className="stk-h2 mb-3">Trenérský tým</h2>
					<Submenutreneri></Submenutreneri>
				</Col>

			</Row>

			<Kurzy></Kurzy>

		</div>
	</Layout>

)

export default SoutezniTanecniParyBam